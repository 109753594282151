import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoEN, NoIndex } from "../components/seo";
import "../assets/css/index.css";
import { translations } from "../locales/cookiesPolicy.js";

const CookiePolicyPage = () => {
  return (
    <Layout page="cookies" language="en">
      <main className="page-container container">
        <div className="page">
          <h1 className="page-title">{translations.en.title}</h1>
          <p className="bold">{translations.en.paragraph1}</p>
          <p>{translations.en.paragraph2}</p>
          <p className="bold">{translations.en.paragraph3}</p>
          <p>{translations.en.paragraph4}</p>
          <p className="bold">{translations.en.paragraph5}</p>
          <p>{translations.en.paragraph6}</p>
          <p>{translations.en.paragraph7}</p>
          <p>{translations.en.paragraph8}</p>
          <p>{translations.en.paragraph9}</p>
          <p>{translations.en.paragraph10}</p>
          <p>{translations.en.paragraph11}</p>
          <p>{translations.en.paragraph12}</p>
          <p className="bold">{translations.en.paragraph13}</p>
          <p>{translations.en.paragraph14}</p>
          <p>{translations.en.paragraph15}</p>
          <p>{translations.en.paragraph16}</p>
          <p>{translations.en.paragraph17}</p>
          <p>{translations.en.paragraph18}</p>
          <p>{translations.en.paragraph19}</p>
          <p className="bold">{translations.en.paragraph20}</p>
          <p>{translations.en.paragraph21}</p>
          <p>{translations.en.paragraph22}</p>
          <p>{translations.en.paragraph23}</p>
          <p>{translations.en.paragraph24}</p>
          <p>{translations.en.paragraph25}</p>
          <p>{translations.en.paragraph26}</p>
          <p className="bold">{translations.en.paragraph27}</p>
          <p>{translations.en.paragraph28}</p>
          <p>{translations.en.paragraph29}</p>
        </div>
      </main>
    </Layout>
  );
};

export default CookiePolicyPage;

export const Head = () => (
  <>
    <SeoEN />
    <NoIndex />
  </>
);
