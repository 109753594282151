export const translations = {
  es: {
    title: "Política de Cookies",
    paragraph1: "1.- ANTECEDENTES",
    paragraph2: "De conformidad con la normativa española que regula el uso de cookies en relación a la prestación de servicios de comunicaciones electrónicas, recogida en el Real Decreto Ley 13/2012 del 30 de marzo, le informamos sobre las cookies utilizadas en el sitio web de KOLIBIRD APP y el motivo de su uso. Asimismo, le informa de que al navegar en el Sitio Web usted está prestando su consentimiento para poder utilizarlas.",
    paragraph3: "2.- ¿QUE SON LAS COOKIES?",
    paragraph4: "Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario. Las cookies se asocian únicamente a un usuario anónimo y su ordenador o dispositivo y no proporcionan referencias que permitan conocer sus datos personales.",
    paragraph5: "3.- TIPOS DE COOKIES",
    paragraph6: "Cookies propias: Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio Solicitado por el usuario.",
    paragraph7: "Cookies de terceros: Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.",
    paragraph8: "Cookies sesión: Son un tipo de cookies diseñadas para recabar y almacenar datos mientras el usuario accede a una página web.",
    paragraph9: "Cookies persistentes: Son un tipo de cookies en el que los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.",
    paragraph10: "Cookies de análisis: Son aquéllas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.",
    paragraph11: "Cookies publicitarias: Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.",
    paragraph12: "Cookies de publicidad comportamental: Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo. Asimismo, es posible que al visitar alguna página web o al abrir algún email donde se publique algún anuncio o alguna promoción sobre nuestros productos o servicios se instale en tu navegador alguna cookie que nos sirve para mostrarte posteriormente publicidad relacionada con la búsqueda que hayas realizado, desarrollar un control de nuestros anuncios en relación, por ejemplo, con el número de veces que son vistos, donde aparecen, a qué hora se ven, etc.",
    paragraph13: "4.- TIPOS DE COOKIES UTILIZADAS POR ESTE SITIO WEB",
    paragraph14: "Las cookies utilizadas en nuestro sitio web, son de sesión y de terceros, y nos permiten almacenar y acceder a información relativa al idioma, el tipo de navegador utilizado, y otras características generales predefinidas por el usuario, así como, seguir y analizar la actividad que lleva a cabo, con el objeto de introducir mejoras y prestar nuestros servicios de una manera más eficiente y personalizada. KOLIBIRD APP no utiliza cookies publicitarias o de publicidad comportamental.",
    paragraph15: "La utilización de las cookies ofrece ventajas en la prestación de servicios dentro de lo que se conoce como la sociedad de la información, puesto que, facilita al usuario la navegación y el acceso a los diferentes servicios que ofrece este sitio web; evita al usuario tener que configurar las características generales predefinidas cada vez que accede al sitio web; favorece la mejora del funcionamiento y de los servicios prestados a través de este sitio web, tras el correspondiente análisis de la información obtenida a través de las cookies instaladas.",
    paragraph16: "__utma | De Terceros (Google Analytics) | 2 años | Se usa para distinguir usuarios y sesiones.",
    paragraph17: "__utmb | De Terceros (Google Analytics) | 30 minutos | Se usa para determinar nuevas sesiones o visitas.",
    paragraph18: "__utmc | De Terceros (Google Analytics) | Al finalizar la sesión | Se configura para su uso con Urchin.",
    paragraph19: "__utmz | De Terceros (Google Analytics) | 6 meses | Almacena el origen o la campaña que explica cómo el usuario ha llegado hasta la página web.",
    paragraph20: "5.- REVOCACIÓN Y ELIMINACIÓN DE COOKIES",
    paragraph21: "En todo momento podrá acceder a la configuración de su navegador aceptando o rechazando todas las cookies, o bien seleccionar aquéllas cuya instalación admite y cuáles no, siguiendo uno de los siguientes procedimientos, que depende del navegador que utilice:",
    paragraph22: "Google Chrome (en el Menú Herramientas) Configuración > Mostrar opciones avanzadas > Privacidad (Configuración de Contenido) > Cookies Más información: https://support.google.com/chrome/answer/95647?hl=es",
    paragraph23: "Microsoft Internet Explorer (en el Menú Herramientas) Opciones de Internet > Privacidad > Avanzada Más información: http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-9",
    paragraph24: "Firefox Opciones > Privacidad > Cookies Más información: http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we/a>",
    paragraph25: "Safari , iPad e iPhone Preferencias > Privacidad Más información: http://www.apple.com/es/privacy/use-of-cookies/",
    paragraph26: "Opera Configuración > Opciones > Avanzado > Cookies Más información: http://help.opera.com/Linux/10.60/es-ES/cookies.html",
    paragraph27: "6.- TERCEROS PRESTADORES DE SERVICIOS",
    paragraph28: "En concreto los terceros prestadores de servicios con los que hemos contratado algún servicio para el que es necesario la utilización de cookies son:",
    paragraph29: "GOOGLE Inc. | Análisis estadístico del sitio web a nivel de contabilización de visitantes, páginas visitadas, palabras clave utilizadas y similares",
  },
  en: {
    title: "Cookies Policy",
    paragraph1: "1.- BACKGROUND",
    paragraph2: "In accordance with Spanish regulations governing the use of cookies in relation to the provision of electronic communications services, contained in Royal Decree Law 13/2012 of March 30, we inform you about the cookies used on the KOLIBIRD APP website and the reason for their use. It also informs you that by browsing the Website you are giving your consent to use them.",
    paragraph3: "2.- WHAT ARE COOKIES?",
    paragraph4: "A cookie is a file that is downloaded to your computer when you access certain web pages. Cookies allow a website, among other things, to store and retrieve information about the browsing habits of a user or their computer and, depending on the information they contain and the way you use your computer, can be used to recognize the user. Cookies are only associated with an anonymous user and their computer or device and do not provide references that allow us to know your personal data.",
    paragraph5: "3.- TYPES OF COOKIES",
    paragraph6: "Own Cookies: These are those that are sent to the user's terminal equipment from a computer or domain managed by the editor itself and from which the service requested by the user is provided.",
    paragraph7: "Third-party cookies: Cookies sent to the user's terminal equipment from a computer or domain that is not managed by the editor, but by another entity that processes the data obtained through the cookies",
    paragraph8: "Session Cookies: These are a type of cookies designed to collect and store data while the user accesses a web page",
    paragraph9: "Persistent cookies: These are a type of cookie in which the data remains stored in the terminal and can be accessed and processed for a period defined by the party responsible for the cookie, which can range from a few minutes to several years",
    paragraph10: "Analysis Cookies: These are those that are well treated by us or by third parties, allow us to quantify the number of users and thus perform the measurement and statistical analysis of the use made by users of the service offered. To do this, your browsing on our website is analyzed in order to improve the range of products or services we offer",
    paragraph11: "Advertising Cookies: These are those that allow the management, in the most effective way possible, of the advertising spaces that, where appropriate, the editor has included on a website, application or platform from which it provides the requested service based on criteria such as the edited content or the frequency at which the ads are displayed.",
    paragraph12: "Behavioral advertising cookies: These cookies allow the management, in the most effective way possible, of the advertising spaces that, where appropriate, the publisher has included on a website, application or platform from which the requested service is provided. These cookies store information on user behavior obtained through the continuous observation of their browsing habits, which allows the development of a specific profile to display advertising based on the same. Likewise, it is possible that when you visit a web page or open an email where an advertisement or promotion about our products or services is published, a cookie may be installed in your browser that allows us to later show you advertising related to the search you have made, develop a control of our ads in relation to, for example, the number of times they are seen, where they appear, at what time they are seen, etc.",
    paragraph13: "4.- TYPES OF COOKIES USED BY THIS WEBSITE",
    paragraph14: "The cookies used on our website, are session and third party cookies, and allow us to store and access information related to language, type of browser used, and other general characteristics predefined by the user, as well as to track and analyze the activity carried out, in order to make improvements and provide our services in a more efficient and personalized way. KOLIBIRD APP does not use advertising or behavioral advertising cookies",
    paragraph15: "The use of cookies offers advantages in the provision of services within what is known as the information society, since it facilitates user navigation and access to the different services offered by this website; it avoids the user having to configure the predefined general characteristics each time they access the website; it favors the improvement of the operation and services provided through this website, after the corresponding analysis of the information obtained through the cookies installed.",
    paragraph16: "__utma | Third-party (Google Analytics) | 2 years | Used to distinguish users and sessions.", paragraph17: "__utma | Third-party (Google Analytics) | 2 years | Used to distinguish users and sessions.",
    paragraph17: "__utmb | Third Party (Google Analytics) | 30 minutes | Used to determine new sessions or visits.", paragraph18: "__utm | Third Party (Google Analytics) | 30 minutes | Used to determine new sessions or visits.",
    paragraph18: "__utmc | Third Party (Google Analytics) | At session end | Configured for use with Urchin.", paragraph19: "__utmc | Third Party (Google Analytics) | At session end | Configured for use with Urchin.",
    paragraph19: "__utmz | Third Party (Google Analytics) | 6 months | Stores the source or campaign that explains how the user arrived at the website.",
    paragraph20: "5.- REVOCATION AND REMOVAL OF COOKIES",
    paragraph21: "At any time you can access your browser settings by accepting or rejecting all cookies, or select those whose installation is allowed and which are not, by following one of the following procedures, depending on the browser you use:",
    paragraph22: "Google Chrome (in the Tools Menu) Settings > Show advanced options > Privacy (Content Settings) > Cookies More information: https://support.google.com/chrome/answer/95647?hl=es",
    paragraph23: "Microsoft Internet Explorer (in the Tools Menu) Internet Options > Privacy > Advanced More information: http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-9",
    paragraph24: "Firefox Options > Privacy > Cookies More information: http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we/a>",
    paragraph25: "Safari , iPad and iPhone Preferences > Privacy More info: http://www.apple.com/es/privacy/use-of-cookies/",
    paragraph26: "Opera Settings > Options > Advanced > Cookies More information: http://help.opera.com/Linux/10.60/es-ES/cookies.html",
    paragraph27: "6.- THIRD PARTY SERVICE PROVIDERS",
    paragraph28: "Specifically, the third party service providers with whom we have contracted a service for which the use of cookies is necessary are:",
    paragraph29: "GOOGLE Inc. | Statistical analysis of the website at the level of counting visitors, pages visited, keywords used and similar",
  }
}